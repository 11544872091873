import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Button,
  Row,
  Col,
  Table,
  FormGroup,
  FormControl,
  FormLabel,
  Container,
  Toast,
  Card,
  Alert
} from "react-bootstrap";
import { Navigate } from "react-router-dom";

const DOMAIN = "https://app.onlyhumans.tech";

function YourContracts() {
  const { authAxios, accessToken, userData } = useAuth();
  const [contracts, setContracts] = useState([]);
  const [contractAddress, setContractAddress] = useState("");

  const [toastShow, setToastShow] = useState(false);
  const [toastVariant, setToastVariant] = useState("normal");
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    async function fetchContracts() {
      try {
        const response = await authAxios.get("/verification/contract");
        setContracts(response.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
        showToast("Error fetching contracts", "error");
      }
    }
    fetchContracts();
    // eslint-disable-next-line
  }, [authAxios]);

  const handleAddContract = async () => {
    try {
      const response = await authAxios.post("/verification/contract", {
        address: contractAddress,
      });
      console.log(response)
      response.data &&
        setContracts((prevContracts) => [...prevContracts, response.data]);
      showToast("Contract added successfully");
    } catch (error) {
      console.log(error)
      showToast(error.response.data.error, "error");
    }
  };

  const showToast = (message, variant = "normal") => {
    setToastVariant(variant);
    setToastMessage(message);
    setToastShow(true);
  };

  const getToastStyle = () => {
    switch (toastVariant) {
      case "error":
        return { backgroundColor: "#f44336", color: "#fff" };
      default:
        return { backgroundColor: "#4caf50", color: "#fff" };
    }
  };

  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  return (
    <>
    {accessToken == null ? (<Navigate to="/"/>) : ("")}
    <div className="YourContractPage main-banner d-flex align-items-center">
      <Container className="">
        <FormGroup className="mb-5 pt-1">
          <FormLabel className="text-white title-font">
            <h3>Contract Address</h3>
          </FormLabel>
          <Row>
            <Col xs={12} md={4} className="mb-2">
              <FormControl
                type="text"
                value={contractAddress}
                onChange={(e) => setContractAddress(e.target.value)}
                placeholder="Enter contract address"
              />
            </Col>
            <Col xs={12} md={2}>
              <Button className="w-100 bg-white border border-1 rounded rounded-5 text-main-color fw-bold" onClick={handleAddContract}>
                Add Contract
              </Button>
            </Col>
          </Row>
        </FormGroup>

        <Row className="my-5 py-5">
          <Col sm={12} md={12}>
            <h3 className="text-white title-font">Verify Links</h3>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <Card className="p-2">
              <Table responsive striped hover>
                <thead>
                  <tr>
                    <th>Contract Address</th>
                    <th>Link</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {contracts.map((contract, index) => (
                    <tr key={index}>
                      <td>{contract.address}</td>
                      <td>
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          className="me-2"
                          onClick={() =>
                            copyToClipboard(`${DOMAIN}/verify/${contract.link}`)
                          }
                        >
                          Copy
                        </Button>
                        <a
                          href={`${DOMAIN}/verify/${contract.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${DOMAIN}/verify/${contract.link}`}
                        </a>
                      </td>
                      <td>{new Date(contract.created_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            </div>
          </Col>
        </Row>
      </Container>
      <Toast
        style={{ ...getToastStyle(), position: "absolute", top: 20, right: 20 }}
        onClose={() => setToastShow(false)}
        show={toastShow}
        delay={3000}
        autohide
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </div>
    </>
  );
}

export default YourContracts;
