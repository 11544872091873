import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, localhost } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { AuthProvider } from "./context/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/Home/Home";
import Page404 from "./pages/404/404";
import YourContracts from './pages/YourContracts/YourContracts'
import VerifyContract from './pages/VerifyContract/VerifyContract'
import Layout from "./components/layout";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    mainnet
  ],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "RainbowKit demo",
  projectId: "9c2221f8c3932299caad995d7d7940e0",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider
      chains={chains}
      theme={darkTheme({
        accentColor: "#7b3fe4",
        accentColorForeground: "white",
        borderRadius: "large",
        fontStack: "system",
        overlayBlur: "small",
      })}
    >
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />} >
              <Route path='/' element={<Home/>} errorElement={<Page404/>}/>
              <Route path='/your-contracts' element={<YourContracts/>} errorElement={<Page404/>}/>
              <Route path='/verify/:contractString' element={<VerifyContract/>} errorElement={<Page404/>}/>
              <Route path='*' element={<Navigate to="/"/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </RainbowKitProvider>
  </WagmiConfig>
);
