import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { Logo } from "../assets";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAuth } from "../context/AuthContext";
import { NavLink, redirect } from "react-router-dom";
import { useEffect } from "react";


export default function Header() {
    const { accessToken, address, web3Login, logout } = useAuth();
    console.log("adonis", address)
    useEffect(() => {
        logout();
    }, [address])
    return (
        <div className="Header">
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand  className="">
                        <NavLink to="/" className="nav-link d-flex align-items-center"><Image src={Logo} width={60} alt="logo" className="me-2" /><span className="logo-title">ONLYHUMANS</span></NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="justify-content-end"
                    >
                        {accessToken &&
                            <NavLink className="nav-link mb-3 mb-md-0 text-center text-md-start" to="/your-contracts">Your Contracts</NavLink>
                        }
                        <Nav className="ms-3 justify-content-center align-items-center">
                            {!accessToken && address ? (
                                <Button className="ms-3" onClick={web3Login}>
                                    Web3 Login
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Nav>
                        <Nav className="mx-3 justify-content-center align-items-center mb-3 mb-md-0">
                            <ConnectButton />
                        </Nav>
                        <Nav className="me-3 justify-content-center align-items-center">
                            {accessToken && (
                                <Button className="loginbutton border border-1" onClick={logout}>
                                    Logout
                                </Button>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}