import axios from "axios";

export async function getNonce(address) {
  try {
    return (await axios.post(
      `https://api.onlyhumans.tech/authentication/nonce_sign_request`,
      {
        address: address,
      }
    )).data.nonce;
  } catch (error) {
    console.error(error);
  }
}

export async function verifyNonce(address, nonce, signature) {
  try {
    return await axios.post(
      `https://api.onlyhumans.tech/authentication/nonce_sign_verify`,
      {
        address: address,
        nonce: nonce,
        signature: signature
      }
    );
  } catch (error) {
    console.error(error);
  }
}
