import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

const CAPTCHAModal = ({ isOpen, onClose, onCAPTCHAChange, contractAddress }) => {
  const [addresses, setAddresses] = useState(['']);
  const MAX_ADDRESSES_ALLOWED = 5;

  const handleAddressChange = (index, value) => {
    const newAddresses = [...addresses];
    newAddresses[index] = value;
    setAddresses(newAddresses);
  };

  const handleAddAddress = () => {
    if (addresses.length < MAX_ADDRESSES_ALLOWED && (addresses.length === 1 || allAddressesFilled())) {
      setAddresses([...addresses, '']);
    }
  };

  const handleRemoveAddress = (index) => {
    if (addresses.length > 1) {
      const newAddresses = [...addresses];
      newAddresses.splice(index, 1);
      setAddresses(newAddresses);
    }
  };

  const handleCAPTCHA = (value) => {
    onCAPTCHAChange(value, addresses);
  };

  const allAddressesFilled = () => {
    return addresses.every(addr => addr.trim() !== '');
  };

  return (

    <Modal show={isOpen} onHide={onClose} animation={true} size='md' centered className='main-bg-color'>
      <Modal.Header className='mx-auto pt-4'>
        <Modal.Title className='text-white title-font'>Verify CAPTCHA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={10} md={8} className='mx-auto'>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className='text-white title-font'>Contract Address</Form.Label>
              <Form.Control type="text" placeholder="Enter Contract Address" value={contractAddress} />
            </Form.Group>
          </Col>
          <Col sm={10} md={8} className='mx-auto'>
            {addresses.map((address, index) => (
              <InputGroup className="mb-3 bg-white border border-0 rounded rounded-2" key={index}>
                <Button
                  onClick={() => handleRemoveAddress(index)}
                  disabled={addresses.length === 1}
                  className='me-1 bg-white opacity-1 border border-0'
                >
                  ➖
                </Button>
                <Form.Control
                  placeholder="Enter your wallet address"
                  aria-label="Address"
                  aria-describedby="basic-addon1"
                  value={address}
                  className='border border-0'
                  onChange={(e) => handleAddressChange(index, e.target.value)}
                />
              </InputGroup>
            ))}
          </Col>
          <Col sm={10} md={8} className='mx-auto'>
            {addresses.length < MAX_ADDRESSES_ALLOWED && allAddressesFilled() && (
              <Button variant="primary" onClick={handleAddAddress} className="mb-3 w-100">
                Add Address
              </Button>
            )}
          </Col>
        </Row>
        <Row className='recapcah-body'>
          <ReCAPTCHA
            sitekey="6LcLlT0oAAAAALx4sC4lqQpwVwU_k68pT6FfBI9y"
            onChange={handleCAPTCHA}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer className='mx-auto mb-3'>
        <Button variant="secondary" className='border border-1' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CAPTCHAModal;
