import { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { Button, Row, Col, Toast, Container, Card } from "react-bootstrap";
import CAPTCHAModal from "../../components/CAPTCHAModal";
import { useParams } from "react-router-dom";

const DOMAIN = "https://api.onlyhumans.tech";

function VerifyContract() {
  const { userData, accessToken, authAxios } = useAuth();

  const { contractString } = useParams();
  const [contractAddress, setContractAddress] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  useEffect(() => {
    async function fetchContract() {
      try {
        const response = await authAxios.get(`${DOMAIN}/verification/contract`, {
          params: {
            uid: contractString,
          },
        });

        if (response.data && response.data.address) {
          setContractAddress(response.data.address);
        }
      } catch (error) {
        console.error("Error fetching contract details:", error);
      }
    }

    fetchContract();
    // eslint-disable-next-line
  }, [contractString]);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCAPTCHAChange = async (value, addresses) => {
    console.log("CAPTCHA value:", value);

    try {
      const response = await authAxios.post(`${DOMAIN}/verification/captcha`, {
        token: value,
        addresses: addresses,
        contractAddress: contractAddress,
      });

      if (response.status === 200) {
        setToastMessage("Verification successful!");
        setToastVariant("success");
        setShowToast(true);
      }
      console.log(response.data);
    } catch (error) {
      console.error("Verification failed:", error.response);
      let errorMsg = "Verification failed.";
      console.log(error.response.data)
      if (error.response && error.response.data && error.response.data.error) {
        errorMsg = error.response.data.error;
      }
      setToastMessage(errorMsg);
      setToastVariant("danger");
      setShowToast(true);
    }

    setModalOpen(false);
  };

  return (
    <>
    <div className="VerifyContractPage main-banner py-5 d-flex align-items-center">
      <Container className="">
      <Card className="p-2">
            <Row className="mb-4">
              <Col xs={12}>
                <h2 className="text-center mb-3 text-white title-font">Contract Verification</h2>
                <p className="text-center text-white mb-3">
                  Complete the CAPTCHA to verify the contract with address:
                  <br />
                  <strong>{contractAddress}</strong>
                </p>
              </Col>
              <Col xs={12} sm={8} md={6} className="mx-auto mb-3">

                <Card.Text className="text-white">
                  <strong className="title-font">Balance:</strong> {userData.balance} ETH
                </Card.Text>
                <Card.Text className="text-white mt-3">
                  <strong className="title-font">Address:</strong> {userData.public_key}
                </Card.Text>
                {userData.balance === 0 && (
                  <Card.Text className="text-warning">
                    Send ETH to this address to reload your balance.
                  </Card.Text>
                )}

              </Col>
            </Row>
            {userData.balance !== 0 && accessToken && (
              <Row className="justify-content-center mb-4">
                <Col xs={12} sm={8} md={6} className="text-center">
                  <Button className='border border-1 rounded rounded-5 bg-white text-main-color mt-5 capcah-button px-5' onClick={handleButtonClick}>Show CAPTCHA</Button>
                  <CAPTCHAModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onCAPTCHAChange={handleCAPTCHAChange}
                    contractAddress={contractAddress}
                  />
                </Col>
              </Row>
            )}
            </Card>
      </Container>
      <Toast
        style={{ position: "fixed", top: 30, right: 30 }}
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body className={`text-${toastVariant}`}>
          {toastMessage}
        </Toast.Body>
      </Toast>
    </div>
    </>
  );
}

export default VerifyContract;
