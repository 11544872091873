import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Alert, Toast } from "react-bootstrap";
import { Button, Col, Container, Row } from 'react-bootstrap';
import CAPTCHAModal from "../../components/CAPTCHAModal";
import Layout from "../Layout/Layout";

function Home() {
  const { authAxios, accessToken } = useAuth();

  const [isModalOpen, setModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCAPTCHAChange = async (value, contractAddress, addresses) => {
    console.log("CAPTCHA value:", value);

    if (!contractAddress.trim()) {
      setToastMessage("Please fill in the contract address.");
      setToastVariant("danger");
      setShowToast(true);
      return;
    }

    try {
      const response = await authAxios.post("/verification/captcha", {
        token: value,
        addresses: addresses,
        contractAddress: contractAddress,
      });

      if (response.status === 200) {
        setToastMessage("Verification successful!");
        setToastVariant("success");
        setShowToast(true);
      }
    } catch (error) {
      console.error("Verification failed:", error.response);
      setToastMessage("Verification failed.");
      setToastVariant("danger");
      setShowToast(true);
    }

    setModalOpen(false);
  };

  return (
    <div className="HomePage">
      <section className='main-banner d-flex align-items-center py-5'>
        <Container className='px-5'>
          <Row>
            <Col sm={12} md={12} className='text-center'>
              <div className="main-banner-title mb-5">Preventing Bots, Prioritizing Humans.</div>
              <div className="main-banner-content mb-5">Say goodbye to predatory bots and welcome genuine, organic growth for your token launches. With OnlyHumans, experience fair and bot-free lanches every single time.</div>
              <Button className='border border-1 rounded rounded-5 bg-white text-main-color mt-5 capcah-button px-5' onClick={handleButtonClick}>Show CAPTCHA</Button>
              {accessToken == null ? (
                <Alert variant="danger" className="mt-5">
                You must be logged in with the deployer wallet of the smart contract you want to add
                </Alert>
              ) : ("")}
              <CAPTCHAModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onCAPTCHAChange={handleCAPTCHAChange}
              />
            </Col>
            <Col xs={12} className="toast-container">
              <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
              >
                <Toast.Header>
                  <strong className="mr-auto">Notification</strong>
                </Toast.Header>
                <Toast.Body className={`text-${toastVariant}`}>
                  {toastMessage}
                </Toast.Body>
              </Toast>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Home;
