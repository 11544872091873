import React, { createContext, useContext, useState, useEffect } from "react";
import { getNonce, verifyNonce } from "../lib/auth";
import { useAccount } from "wagmi";
import { signMessage } from "@wagmi/core";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const baseURL = "https://api.onlyhumans.tech";

  const { address } = useAccount();

  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refreshToken"));
  const [userData, setUserData] = useState({});

  const getUserData = async () => {
    let userDataResponse = await authAxios.get(`${baseURL}/authentication/user`)
    let data = userDataResponse.data;
    setUserData({
      pk: data.id,
      public_key: data.public_key,
      balance: data.eth_balance
    })
  }

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      getUserData();
    } else {
      localStorage.removeItem("accessToken");
    }
    console.log(userData)
  // eslint-disable-next-line
  }, [accessToken]);

  useEffect(() => {
    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    } else {
      localStorage.removeItem("refreshToken");
    }
  }, [refreshToken]);

  const [nonce, setNonce] = useState(null);

  const web3Login = async () => {
    if (address) {
      let nonce_requested = await getNonce(address);
      let signed = await signMessage({ message: nonce_requested });
      let res = await verifyNonce(address, nonce_requested, signed);
      setAccessToken(res.data.access_token);
      setRefreshToken(res.data.refresh_token);
    }
  };

  const authAxios = axios.create({
    baseURL: baseURL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        console.log(accessToken)
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      try {
        const originalRequest = error.config;
        console.log('ERROR')
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const newAccessToken = await refreshAccessToken(refreshToken);
          if (newAccessToken) {
            setAccessToken(newAccessToken);
  
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return authAxios(originalRequest);
          }
        }
      // window.location.reload()
      return Promise.reject(error)
      } catch(e) {
        return
      }
    }
  );

  const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await axios.post(
        `${baseURL}/authentication/login/refresh`,
        {
          refresh: refreshToken,
        }
      );
      return response.data.access;
    } catch (error) {
      console.error("Error refreshing token", error);
      return null;
    }
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  return (
    <AuthContext.Provider
    value={{
      authAxios,
      accessToken,
      setAccessToken,
      refreshToken,
      setRefreshToken,
      nonce,
      setNonce,
      web3Login,
      address,
      logout,
      isAuthenticated: !!accessToken,
      isWalletConnected: !!address,
      userData
    }}
  >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
