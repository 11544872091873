export const NavMenuData = [
    {
        title : "DOCS",
        link : "https://onlyhumans.gitbook.io/onlyhumans-documentation/"
    },
    {
        title : "TWITTER",
        link : "https://twitter.com/onlyhumanstech"
    },
    {
        title : "TELEGRAM",
        link : ""
    },
];

